// extracted by mini-css-extract-plugin
export var root = "PlasmicHomepage-module--root--34bG1";
export var homepage = "PlasmicHomepage-module--homepage--1phsc";
export var freeBox__oewb7 = "PlasmicHomepage-module--freeBox__oewb7--e-eS2";
export var frame1 = "PlasmicHomepage-module--frame1--2uNOJ";
export var freeBox__jS9N7 = "PlasmicHomepage-module--freeBox__jS9N7--1DjDI";
export var freeBox__rzFq6 = "PlasmicHomepage-module--freeBox__rzFq6---Dpbv";
export var freeBox__xihO = "PlasmicHomepage-module--freeBox__xihO--3y1CD";
export var freeBox__czCMp = "PlasmicHomepage-module--freeBox__czCMp--2vvZZ";
export var frame2 = "PlasmicHomepage-module--frame2--2kHFu";
export var freeBox__tjr6P = "PlasmicHomepage-module--freeBox__tjr6P--3TiHn";
export var freeBox__hj3Zq = "PlasmicHomepage-module--freeBox__hj3Zq--2FokT";
export var freeBox__gjlrz = "PlasmicHomepage-module--freeBox__gjlrz--2pA3A";
export var frame3 = "PlasmicHomepage-module--frame3--1ogXj";
export var freeBox__vhMhh = "PlasmicHomepage-module--freeBox__vhMhh--2ZxM6";
export var clipNewSamplesForResearch1 = "PlasmicHomepage-module--clipNewSamplesForResearch1--10Xf9";
export var freeBox___5QbbH = "PlasmicHomepage-module--freeBox___5QbbH--2FMMs";
export var freeBox___9WnLr = "PlasmicHomepage-module--freeBox___9WnLr--1CSfg";
export var frame7 = "PlasmicHomepage-module--frame7--3GWYj";
export var freeBox__xezW1 = "PlasmicHomepage-module--freeBox__xezW1--3xvzP";
export var rectangle6 = "PlasmicHomepage-module--rectangle6--2b7vw";
export var rectangle8 = "PlasmicHomepage-module--rectangle8--18KKT";
export var drosophila1 = "PlasmicHomepage-module--drosophila1--3-xox";
export var rectangle4 = "PlasmicHomepage-module--rectangle4--31biE";
export var freeBox__iVjGv = "PlasmicHomepage-module--freeBox__iVjGv--16zEB";
export var freeBox__cFiKf = "PlasmicHomepage-module--freeBox__cFiKf--3EU-P";
export var functionAndCellularLocalizationOfProteinsInDrosophilaMelanogaster = "PlasmicHomepage-module--functionAndCellularLocalizationOfProteinsInDrosophilaMelanogaster--3miLy";
export var rectangle7 = "PlasmicHomepage-module--rectangle7--2IPIT";
export var img__m0LNk = "PlasmicHomepage-module--img__m0LNk--1NWuE";
export var freeBox__kSo1S = "PlasmicHomepage-module--freeBox__kSo1S--kj3fN";
export var freeBox__pD48 = "PlasmicHomepage-module--freeBox__pD48--3WDa-";
export var freeBox__t8Od = "PlasmicHomepage-module--freeBox__t8Od--2Eiyu";
export var freeBox__uiAnk = "PlasmicHomepage-module--freeBox__uiAnk--3ogSa";
export var frame6 = "PlasmicHomepage-module--frame6--1uFLL";
export var akarIconstwitterFill = "PlasmicHomepage-module--akarIconstwitterFill--3KIye";
export var svg___6Uegb = "PlasmicHomepage-module--svg___6Uegb--1lyRY";
export var akarIconsfacebookFill = "PlasmicHomepage-module--akarIconsfacebookFill--1utsC";
export var svg__zScjC = "PlasmicHomepage-module--svg__zScjC--3jlT6";
export var akarIconslinkedinFill = "PlasmicHomepage-module--akarIconslinkedinFill--3YPan";
export var svg__aZN1 = "PlasmicHomepage-module--svg__aZN1--1OoXK";
export var svg___09NtU = "PlasmicHomepage-module--svg___09NtU--2yY8f";
export var svg__uW2R9 = "PlasmicHomepage-module--svg__uW2R9--1I1bW";
export var freeBox__mnn56 = "PlasmicHomepage-module--freeBox__mnn56--3Slkk";
export var drosophila2 = "PlasmicHomepage-module--drosophila2--dNkVz";
export var frame8 = "PlasmicHomepage-module--frame8--28ck5";
export var freeBox__jMseU = "PlasmicHomepage-module--freeBox__jMseU--2sceR";
export var frame9 = "PlasmicHomepage-module--frame9--1pn_j";
export var freeBox__uLgy = "PlasmicHomepage-module--freeBox__uLgy--1YFHC";
export var component1 = "PlasmicHomepage-module--component1--1peoo";
export var frame10 = "PlasmicHomepage-module--frame10--3czkc";
export var freeBox__ggAOo = "PlasmicHomepage-module--freeBox__ggAOo--4xh0Q";
export var rectangle9 = "PlasmicHomepage-module--rectangle9--2C2VE";
export var frame11 = "PlasmicHomepage-module--frame11--2ufEn";
export var freeBox__amfI6 = "PlasmicHomepage-module--freeBox__amfI6--1DbF7";
export var rectangle10 = "PlasmicHomepage-module--rectangle10--1Qogd";
export var frame12 = "PlasmicHomepage-module--frame12--37AwS";
export var freeBox___0376U = "PlasmicHomepage-module--freeBox___0376U--12zu8";
export var rectangle11 = "PlasmicHomepage-module--rectangle11--cu_kT";
export var frame13 = "PlasmicHomepage-module--frame13--1Rxnx";
export var freeBox__bEkm3 = "PlasmicHomepage-module--freeBox__bEkm3--1B5aY";
export var rectangle12 = "PlasmicHomepage-module--rectangle12--2tSz6";
export var homepageMobile = "PlasmicHomepage-module--homepageMobile--2slYi";
export var freeBox___0Ne1W = "PlasmicHomepage-module--freeBox___0Ne1W--15Y9a";
export var freeBox__oMtaK = "PlasmicHomepage-module--freeBox__oMtaK--2xTFs";
export var freeBox__uUpwK = "PlasmicHomepage-module--freeBox__uUpwK--3isTp";
export var freeBox__roqfr = "PlasmicHomepage-module--freeBox__roqfr---TNA4";
export var freeBox__zcUwp = "PlasmicHomepage-module--freeBox__zcUwp--1XeT1";
export var freeBox__w0QUl = "PlasmicHomepage-module--freeBox__w0QUl--4FhUd";
export var freeBox__z2Koc = "PlasmicHomepage-module--freeBox__z2Koc--2Amgy";
export var freeBox___2UPws = "PlasmicHomepage-module--freeBox___2UPws--JEplP";
export var freeBox__iZDt = "PlasmicHomepage-module--freeBox__iZDt--MpXPF";
export var freeBox__qZvn8 = "PlasmicHomepage-module--freeBox__qZvn8--3GP6q";
export var freeBox__q0Oxy = "PlasmicHomepage-module--freeBox__q0Oxy--ll7V4";
export var freeBox__yR5X9 = "PlasmicHomepage-module--freeBox__yR5X9--1ige2";
export var svg___67FQh = "PlasmicHomepage-module--svg___67FQh--MOEu3";
export var freeBox__oedLs = "PlasmicHomepage-module--freeBox__oedLs--36iC8";
export var freeBox__s7HnQ = "PlasmicHomepage-module--freeBox__s7HnQ--1Imlh";
export var functionAndCellularLocalizationOfProteinsInDrosophilaMelanogaster2 = "PlasmicHomepage-module--functionAndCellularLocalizationOfProteinsInDrosophilaMelanogaster2--nmGiB";
export var freeBox__mQpfj = "PlasmicHomepage-module--freeBox__mQpfj--1M4Un";
export var img__m0VhR = "PlasmicHomepage-module--img__m0VhR--3scRE";
export var freeBox__dvBGl = "PlasmicHomepage-module--freeBox__dvBGl--oL55x";
export var freeBox__q02Sb = "PlasmicHomepage-module--freeBox__q02Sb--3fgqy";
export var freeBox__q6YoX = "PlasmicHomepage-module--freeBox__q6YoX--1nkCP";
export var freeBox___2Zpw = "PlasmicHomepage-module--freeBox___2Zpw--2AK58";
export var freeBox__nU39 = "PlasmicHomepage-module--freeBox__nU39--10eXc";
export var akarIconstwitterFill2 = "PlasmicHomepage-module--akarIconstwitterFill2--kZKVV";
export var svg__gTy28 = "PlasmicHomepage-module--svg__gTy28--2MnrC";
export var akarIconsfacebookFill2 = "PlasmicHomepage-module--akarIconsfacebookFill2--2C8ge";
export var svg__yyeOl = "PlasmicHomepage-module--svg__yyeOl--jeOEC";
export var akarIconslinkedinFill2 = "PlasmicHomepage-module--akarIconslinkedinFill2--2KHqM";
export var svg___6Xg8 = "PlasmicHomepage-module--svg___6Xg8--OT5me";
export var svg__mrdVl = "PlasmicHomepage-module--svg__mrdVl--3gubG";
export var svg__iXeQk = "PlasmicHomepage-module--svg__iXeQk--u0KiL";
export var freeBox__eEoXs = "PlasmicHomepage-module--freeBox__eEoXs--kO3KQ";
export var freeBox__ku3El = "PlasmicHomepage-module--freeBox__ku3El--1dudW";
export var freeBox___8TPnT = "PlasmicHomepage-module--freeBox___8TPnT--E4Mc2";
export var freeBox__e5HeR = "PlasmicHomepage-module--freeBox__e5HeR--w62n0";
export var freeBox__xPw4C = "PlasmicHomepage-module--freeBox__xPw4C--3qLNa";
export var freeBox__bbf4 = "PlasmicHomepage-module--freeBox__bbf4--10ePX";
export var component2 = "PlasmicHomepage-module--component2--3yfcR";
export var freeBox___3BCpb = "PlasmicHomepage-module--freeBox___3BCpb--1FkiK";
export var freeBox__tcWa8 = "PlasmicHomepage-module--freeBox__tcWa8--1dbeM";
export var freeBox__qgCvs = "PlasmicHomepage-module--freeBox__qgCvs--29APu";
export var freeBox___1AfgG = "PlasmicHomepage-module--freeBox___1AfgG--JNI20";
export var freeBox__oisBo = "PlasmicHomepage-module--freeBox__oisBo--3W1b6";
export var freeBox__zlbe9 = "PlasmicHomepage-module--freeBox__zlbe9--1cAO6";
export var freeBox__k8YjU = "PlasmicHomepage-module--freeBox__k8YjU--ve1az";
export var freeBox__jYTdA = "PlasmicHomepage-module--freeBox__jYTdA--W2y2O";
export var freeBox__vrirY = "PlasmicHomepage-module--freeBox__vrirY--3NUCS";
export var freeBox__nIhtD = "PlasmicHomepage-module--freeBox__nIhtD--30W_L";
export var freeBox__g7Qfv = "PlasmicHomepage-module--freeBox__g7Qfv--3LcLi";
export var freeBox__dDjlC = "PlasmicHomepage-module--freeBox__dDjlC--1zDNf";